class StickyHeader extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.revealThreshold = window.innerWidth > 1024 ? this.attributes["hide-threshold"].value || 300 : 100;
    this.headerWrapper = document.getElementById("shopify-section-header");
    this.announcementBar = document.querySelector(
      "#shopify-section-announcement-bar"
    );
    this.topbar = document.querySelector("[announcement-bar]");
    this.header = document.querySelector("[header]");
    this.deskLogo = document.querySelector("[desk-logo]");
    this.toggleBtn = document.querySelectorAll('[data-role="nav-toggle"]');
    // filter sticky
    this.desktopStickyFilter = document.querySelector(
      '[data-role="filter-sticky-desktop"]'
    );
    this.nestedToggleBtns = document.querySelectorAll(
      '[data-role="nested-toggle"]'
    );
    // conditionally adding sticky properties to header
    this.headerWrapper.style.position = "sticky";
    this.announcementBar.style.position = "sticky";
    this.topbar.style.position = "sticky";
    this.headerWrapper.style.top = this.announcementBar.clientHeight + "px";
    this.announcementBar.style.top = "0px";
    this.headerWrapper.style.zIndex = "100";
    this.headerWrapper.style.transition = "all 0.3s ease-in-out";
    this.announcementBar.style.zIndex = "101";

    this.headerBounds = {};
    this.currentScrollTop = 0;
    this.preventReveal = false;
    this.predictiveSearch = document.querySelector("predictive-search");

    this.onScrollHanlder = this.onScroll.bind(this);
    this.hideHanlderOnScrollUp = () => (this.preventReveal = true);

    window.addEventListener("scroll", this.onScrollHanlder, false);
    // binding event to navigation btns
    Array.from(this.toggleBtn).forEach((el) =>
      el.addEventListener("click", this.toggleNav.bind(this))
    );
    Array.from(this.nestedToggleBtns).forEach((el) => {
      el.addEventListener("click", this.toggleNestedNav.bind(this, el));
    });
    this.createObserver();
    this.resizeObserver();
  }

  disconnectedCallback() {}

  onScroll() {
    const scrollTop = window.pageYOffset;
    if (scrollTop > this.currentScrollTop && scrollTop > this.revealThreshold) {
      requestAnimationFrame(this.hide.bind(this));
      this.desktopStickyFilter ? this.desktopStickyFilter.style.transition = "none" : "";
      this.header.classList.add("is-sticky");
      let navAnnoucementHeight =
        document.querySelector('[data-role="navigation"]').clientHeight +
        document.querySelector('[data-role="announcement"]').clientHeight - 2 +
        "px";

      // sticky filter collection page
      this.desktopStickyFilter
        ? (this.desktopStickyFilter.style.top = navAnnoucementHeight)
        : "";
    } else if (scrollTop < this.currentScrollTop) {
      requestAnimationFrame(this.show.bind(this));
      this.desktopStickyFilter ? this.desktopStickyFilter.style.transition = "all 0.5s ease" : "";
      let navAnnoucementHeight =
        document.querySelector("header").clientHeight +
        document.querySelector('[data-role="announcement"]').clientHeight - 2 +
        "px";
      // sticky filter collection page
      this.desktopStickyFilter
        ? (this.desktopStickyFilter.style.top = navAnnoucementHeight)
        : "";
    }

    this.currentScrollTop = scrollTop;
  }

  // translate header for desktop only to hide header logo
  hide() {
    document.body.classList.add("logoDisplay");
    let translateVal =
      window.innerWidth > 1023 ? this.deskLogo.clientHeight + 1 : 0;
    translateVal == 0
      ? this.headerWrapper.style.removeProperty("transform")
      : (this.headerWrapper.style.transform = `translateY(-${translateVal}px)`);
    this.headerWrapper.style.transition = "all 500ms";
  }

  // remove translate property to display header logo
  show() {
    document.body.classList.remove("logoDisplay");
    this.headerWrapper.style.removeProperty("transform");
    this.headerWrapper.style.transition = "all 500ms";
  }
  // navigation toggle
  toggleNav() {
    this.classList.toggle("nav-toggle");
    this.announcementBar.classList.toggle("toggle");
    this.classList.contains("nav-toggle")
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    this.classList.contains("nav-toggle") && window.innerWidth < 768
      ? (this.headerWrapper.style.top = "0px")
      : (this.headerWrapper.style.top =
          this.announcementBar.clientHeight + "px");
    this.classList.contains("nav-toggle") && window.innerWidth < 768
      ? (this.announcementBar.style.zIndex = "0")
      : (this.announcementBar.style.zIndex = "101");
    //position header from top in mobile and tablet
    let navAnnoucementHeight =
      document.querySelector('[data-role="navigation"]').clientHeight +
      document.querySelector('[data-role="announcement"]').clientHeight +
      "px";
    window.innerWidth >= 768 && window.innerWidth <= 1024
      ? (document.querySelector("nav").style.top = `${navAnnoucementHeight}`)
      : document.querySelector("nav").removeAttribute("style");
    window.innerWidth < 768
      ? (document.querySelector("nav").style.top = `${
          document.querySelector('[data-role="navigation"]').clientHeight
        }px`)
      : "";
  }
  // toggle L2 navigation
  toggleNestedNav(el) {
    let nav = el.closest("li");
    Array.from(this.nestedToggleBtns).forEach((btn) => {
      btn.closest("li") != nav
        ? btn.closest("li").classList.remove("is-active")
        : "";
    });
    nav.classList.toggle("is-active");
  }
  createObserver() {
    let ob = new IntersectionObserver((entries, ob) => {
      this.headerBounds = entries[0].intersectionRect;
      ob.disconnect();
    });
    ob.observe(this.headerWrapper);
  }

  // window resize observer
  resizeObserver() {
    let resizeObserve = new ResizeObserver((entries) => {
      //position header from top in mobile and tablet
      let navAnnoucementHeight =
        document.querySelector('[data-role="navigation"]').clientHeight +
        document.querySelector('[data-role="announcement"]').clientHeight +
        "px";
      window.innerWidth >= 768 && window.innerWidth < 1024
        ? (document.querySelector("nav").style.top = `${navAnnoucementHeight}`)
        : document.querySelector("nav").removeAttribute("style");
      window.innerWidth < 768
        ? (document.querySelector("nav").style.top = `${
            document.querySelector('[data-role="navigation"]').clientHeight
          }px`)
        : "";
      window.innerWidth >= 1024
        ? document.querySelector("nav").removeAttribute("style")
        : "";
      this.revealThreshold = window.innerWidth > 1024 ? this.attributes["hide-threshold"].value || 300 : 100;
    });
    resizeObserve.observe(document.body);
  }
}

customElements.define("sticky-header", StickyHeader);
